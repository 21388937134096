import Helmet from "react-helmet"
import React from "react"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function ThankYouClear() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
     @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
     * {
     	font-family: 'Roboto', sans-serif !important;
      margin:0;
      padding:0;
      box-sizing:border-box;
     }
     section {
     	position: relative;
     	display: flex;
     	flex-direction: column;
     	justify-content: center;
     	align-items: center;
     	height: 100vh;
     	width: 100%;
     	background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
     }
     .topLeftLogo {
     	position: absolute;
     	top: 20px;
     	left: 20px;
     	width: 150px;
     }
     .mlLogo {
     	position: relative;
     	display: block;
     	width: 190px;
     	padding: 0 0 44px 0;
     	margin: 0 auto;
     }
     h1 {
     	color: #777;
     	text-align: center;
     	padding: 0 0 32px 0;
     	font-size: 25pt;
     	font-weight: 500;
     	line-height: 200%;
     }
     ol {
     	position: relative;
     	counter-reset: my-awesome-counter;
     	list-style: none;
     	padding-left: 0;
     	text-align: left;
     	font-size: 24px;
     	color: #777;
     	max-width: 800px;
     	margin: 0 auto;
     }
     ol li {
     	margin: 0 0 24px 0;
     	counter-increment: my-awesome-counter;
     	position: relative;
     	line-height: 100%;
     }
     ol li img {
     	width: 40px;
     	display: inline;
     	vertical-align: middle;
     	animation: zoom-in-zoom-out 3s ease infinite;
     }
     ol li::before {
     	content: counter(my-awesome-counter);
     	color: #0319ac;
     	font-size: 24px;
     	font-weight: 700;
     	position: absolute;
     	--size: 36px;
     	left: calc(-1 * var(--size) - 12px);
     	line-height: var(--size);
     	width: var(--size);
     	height: var(--size);
     	top: -2px;
     	background: #f1f1f1;
     	border-radius: 50%;
     	text-align: center;
     	box-shadow: 1px 1px 0 #777777;
     }
     
     @keyframes zoom-in-zoom-out {
     	0% {
     		transform: scale(1, 1);
     	}
     	25% {
     		transform: scale(1, 1);
     	}
     	50% {
     		transform: scale(1.25, 1.25);
     	}
     	75% {
     		transform: scale(1, 1);
     	}
     	100% {
     		transform: scale(1, 1);
     	}
     }
     }
    `}
    </style>
  
    <title>Thank you for downloading</title>
    </Helmet>
    <section>
    <img class="topLeftLogo" src="/img/clear.png" alt="Clear Logo" />
    <img class="mlLogo" src="/img/logo.png" alt="Manuals Library Logo" />
      <h1>Thank You for Installing Manuals Library by Clear.</h1>
        <ol>
          <li>Look for the Manuals Library Icon <img src="/img/ml-icon.png" alt="Manuals Library Icon" /> in the app bar at the top of your screen.</li>
          <li>Click on it to start searching for manuals!</li>
        </ol>
	<RawFooter></RawFooter>
    </section>
    </>
  )
}
